import * as THREE from 'three'
  let material = new THREE.MeshPhongMaterial({
    color: 0xFFFFFF,
    emissive: 0xCCCCCC,
    specular: 0x0000CC,
    shininess: 1,
    flatShading: true,
    opacity: 1
  });

export default class IsoPlanet extends THREE.Object3D {


  constructor(size = 2.5, radius = 20, tilt = Math.PI / 10, rotationDelta = 0.008){
    super();

    this.type = 'ISOPlanet';
    this.size = size;
    this.radius = radius;
    this.tilt = tilt;
    this.suns  = []
    this.rotationDelta = rotationDelta
  }

  generate(scene){
    let sun, sun2, sun3;
    let group = new THREE.Object3D()

    sun = new THREE.Mesh(new THREE.IcosahedronGeometry(this.size, 1), material);
    group.add(sun);
    this.suns.push(sun)

    sun2 = new THREE.Mesh(new THREE.IcosahedronGeometry(this.size, 1), material);
    sun2.rotation.x = 1;
    group.add(sun2);
    this.suns.push(sun2)

    sun3 = new THREE.Mesh(new THREE.IcosahedronGeometry(this.size, 1), material);
    sun3.rotation.z = 1;
    group.add(sun3);
    this.suns.push(sun3)

    group.position.x = this.radius;

    this.rotation.y = Math.PI * 2 * Math.random()

    this.add(group)
    this.rotation.x = this.tilt
    scene.add(this)
    return [sun, sun2, sun3]
  }

  updateProps(speedMultiplier = 1) {
    this.suns[0].rotation.x += this.rotationDelta * speedMultiplier
    this.suns[1].rotation.y += this.rotationDelta * speedMultiplier
    this.suns[2].rotation.z += this.rotationDelta * speedMultiplier
    this.rotation.y += this.rotationDelta * speedMultiplier
  }

}
