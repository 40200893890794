import React, { useEffect, useRef, useCallback, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { generate, startCubeIntro, updateScene } from "canvas/Orchestrator"

import {useEventListener} from 'hooks/Window'

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
`

const Border = styled.div`
 background: url("white_paper.png");
 z-index: 9999;
 position: fixed;
`

const BorderVerticalLeft = styled(Border)`
  width: 10px;
  height: 100vh;
  top: 0;
`

const BorderVerticalRight = styled(BorderVerticalLeft)`
  right: 0;
`

const BorderHorizontalTop = styled(Border)`
  width: 100vw;
  height: 10px;
  box-shadow: rgba(30, 30, 30, 0.1) 4px 4px 0px 0px;
`

const BorderHorizontalBottom = styled(BorderHorizontalTop)`
  bottom: 0px;
  box-shadow: rgba(30, 30, 30, 0.1) -4px -4px 0px 0px;
`

  //${props => props.theme.main}

export const Frame = ({ children }) => {
  return (
    <Container>
      <BorderHorizontalTop />
      <BorderHorizontalBottom />
      <BorderVerticalLeft/>
      <BorderVerticalRight/>
    </Container>
  )
}

Frame.propTypes = {
  children: PropTypes.node.isRequired,
}

