import React, { useEffect, useRef, useCallback, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { generate, updateScene, startScene, startInitAnimation, debugData, timelineParams, timelineStateReducer } from "canvas/Orchestrator"

import {useEventListener} from 'hooks/Window'
import { Frame } from "components/Frame"

import { EMPTY, INITIALIZED, HOME } from "canvas/SceneEnum"

export const TimelineStateContext = React.createContext();
export const TimelineDispatchContext = React.createContext(startScene);

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: -9999;
  width: 100vw;
  height: 100vh;
  padding: 10px;
`

const ComicOverlay = styled.div`
    background: url("billie-holiday.png");
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    width: 100vw;
    height: 100vh;  
`
const CanvasContainer = styled.div`
  z-index: -9999;
  width: 100%;
  height: 100%;
`

const SCanvas = styled.canvas`
`

const pointer = {
  x: 0,
  y: 0 ,
  positionX: 0,
  positionY: 0
}

export const Backdrop = ({ children }) => {
  const requestRef = useRef()
  const previousTimeRef = useRef()
  const containerRef = useRef(null)
  const canvasRef = useRef(null)

  const [timelineState, setTimelineState] = React.useReducer(timelineStateReducer, timelineState)

  const handler = ({ clientX, clientY }) => {
      pointer.x = clientX
      pointer.y = clientY
      pointer.positionX = clientX / window.innerWidth
      pointer.positionY = clientY / window.innerHeight
  }

  const animate = time => {
    previousTimeRef.current = time
    requestRef.current = requestAnimationFrame(animate)
    updateScene(getClientParams(), debugData)
  }
  const isClient = typeof window === 'object'
  const getClientParams = () => {
    return {
      width: isClient ? containerRef.current.offsetWidth - 20 : undefined,
      height: isClient ? containerRef.current.offsetHeight - 20 : undefined,
      pointer
    }
  }

  useEventListener('mousemove', handler);

  useEffect(() => {
    generate(canvasRef.current, getClientParams(), debugData, setTimelineState)
    requestRef.current = requestAnimationFrame(animate)
    startInitAnimation()

    return () => cancelAnimationFrame(requestRef.current)
  }, []) // Make sure the effect runs only once

  return (
    <>
      <TimelineStateContext.Provider value={timelineState}>
      <TimelineDispatchContext.Provider value={startScene}>
        {children}
        <Frame/>
        <Container>
          <CanvasContainer ref={containerRef}>
            <ComicOverlay />
            <SCanvas ref={canvasRef} />
          </CanvasContainer>
        </Container>
      </TimelineDispatchContext.Provider>
      </TimelineStateContext.Provider>
    </>
  )
}

Backdrop.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Backdrop
