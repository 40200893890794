import { Frame } from "components/Frame"
import React from "react"
import Backdrop from "components/Backdrop"

export const wrapRootElement = ({ element }) => (
  <>
    <Backdrop>
      {element}
    </Backdrop>
    </>
)
