import * as THREE from 'three'
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';

import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass.js';

// import { RGBShiftShader } from 'three/examples/jsm/shaders/RGBShiftShader.js';
import { DotScreenShader } from 'three/examples/jsm/shaders/DotScreenShader.js';
import { AfterimagePass } from 'three/examples/jsm/postprocessing/AfterimagePass.js';

export default class World extends THREE.Object3D {
  constructor(canvas, { width, height }, props){
    super();

    this.type = 'World';
    this.canvas = canvas;
    this.generate(canvas, width, height)
    this.updateProps(props)
  }

  generate = (canvas, width, height) => {
    this.renderer = new THREE.WebGLRenderer({canvas });
    this.renderer.setSize(width, height);
    //TODO Check device pixel ratio
    this.renderer.setPixelRatio(2)
    this.renderer.setClearColor(0x111111, 1);

    this.scene = new THREE.Scene();
    this.scene.fog = new THREE.Fog( 0x111111, 1, 700 );

    this.camera = new THREE.PerspectiveCamera(25, width / height, 1, 1000);
    this.camera.position.z = 100;

    this.setupLights(this.scene)
    this.setupEffects(this.renderer, this.scene, this.camera)
  }

  setupEffects = (renderer, scene, camera) => {
    let composer = new EffectComposer( renderer );
    composer.addPass( new RenderPass( scene, camera ) );

    this.dotEffect = new ShaderPass( DotScreenShader );
    // this.shiftEffect = new ShaderPass( RGBShiftShader );
    this.afterimagePass = new AfterimagePass(0.96);

    composer.addPass( this.afterimagePass );
    composer.addPass( this.dotEffect );

    this.composer = composer

    return composer
  }

  setupLights = (scene) => {
    let light = new THREE.DirectionalLight(0xff4f4f);
    light.position.set(4, 4, 4);
    scene.add(light);
    light = new THREE.DirectionalLight(0x2f2fff);
    light.position.set(-4, -4, -4);
    scene.add(light);
  }

  // setupControls = (renderer, camera) => {
  //   controls = new OrbitControls(camera, renderer.domElement);
  //   controls.update();
  //   controls.minPolarAngle = 0;
  //   controls.maxPolarAngle = Math.PI * 0.5;
  //   controls.maxDistance = 200;
  //   controls.minDistance = 30;
  // }

  updateProps = ({dotScale}) => {
    //TODO Link to events
    this.dotEffect.uniforms[ 'scale' ].value = dotScale;
  }

  update = ({ width, height}) => {
    this.camera.aspect = width/height;
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(width, height)
    this.composer.render(this.scene, this.camera);
  }

  add = (threeObject) => {
    this.scene.add(threeObject)
  }
}
