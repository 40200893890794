import { Back, Linear, TweenMax } from "gsap/gsap-core"
import * as THREE from 'three'

let material = new THREE.MeshPhongMaterial({
  color: 0xFFFFFF,
  emissive: 0xCCCCCC,
  specular: 0x0000CC,
  shininess: 1,
  flatShading: true,
  opacity: 1
});

const CUBE_SIZE = 2 /* width, height */
const GRID = 100 /* cols, rows */
const TOTAL_CUBES = (GRID * GRID)
// const WALL_SIZE = (GRID * CUBE_SIZE)

export default class Backdrop extends THREE.Object3D {
  constructor(size = 2, grid = 70, rotationDelta = 0.004){
    super();

    this.type = 'Backdrop';
    this.size = size;
    this.grid = grid;
    this.rotationDelta = rotationDelta
    this.cubeGroup = new THREE.Object3D()
    this.cubes  = []
    this.generate()
  }

  generate(){
    this.generateCubes()
    this.setCubeToPlanePositions(false)
  }

  startScene(sceneName) {
    switch (sceneName) {
      case 'intro':
        this.setCubesToGalaxyPositions(true)
        break;
    }
  }

  generateCubes() {
    let i

    let geometry = new THREE.BoxGeometry(this.size, this.size, this.size)
    let cube
    for (i = 0; i < this.grid * this.grid; i++) {
      cube = new THREE.Mesh(geometry, material)
      cube.castShadow = true
      cube.receiveShadow = true
      this.cubeGroup.add(cube)
      this.cubes.push(cube)
    }

    this.add(this.cubeGroup)
  }

  setCubeToPlanePositions(animated) {
    let i, col, row, x, y
    i = col = row = 0

    this.cubes.forEach((cube) => {
      if ((i % GRID) === 0) {
        col = 1
        row++
      } else col++

      x = -(((this.grid * this.size) / 2) - ((this.size) * col) + (this.size/2))
      y = -(((this.grid * this.size) / 2) - ((this.size) * row) + (this.size/2))

      if(!animated) {
        cube.position.set(x, y, 0)
      } else {
        //TODO Add tween
        cube.position.set(x, y, 0)
      }
      i++
    })
  }

  setCubesToGalaxyPositions(animated) {
    const minDelay = 0.4
    const minDuration = 2.5

    const PI2 = Math.PI * 2
    const SPACE_MULTIPLIER = 4
      const ease = Linear.easeOut

    this.cubes.forEach(function(cube) {
      //Reset to plane position?
      const delay = minDelay + ((Math.abs(cube.position.y / 30)) + Math.abs(cube.position.x / 30)) * 0.5
      const duration = minDuration + Math.random() / 6
      const rotate = PI2 * Math.random()

      const zPosition = -20 + (2 - Math.abs(Math.cos(cube.position.x)) - Math.abs(Math.sin(cube.position.y))) * -250
      // const zPosition = -20 + Math.random() * -450
      const scale = 0.7 + Math.random() / 5


      if(!animated) {
        cube.position.x = cube.position.x * SPACE_MULTIPLIER;
        cube.position.y = cube.position.y * SPACE_MULTIPLIER;
        cube.position.z = zPosition;
        cube.scale.x = scale;
        cube.scale.y = scale;
        cube.scale.z = scale;
        cube.rotation.z = rotate;
      } else {
        TweenMax.to( cube.position, duration, {
          x: cube.position.x * SPACE_MULTIPLIER,
          y: cube.position.y * SPACE_MULTIPLIER,
          z: zPosition,
          ease,
          delay,
        } );

        TweenMax.to( cube.scale, duration, {
          x: scale,
          y: scale,
          z: scale,
          ease,
          delay,
        } );

        TweenMax.to( cube.rotation, duration, {
          z: rotate,
          ease,
          delay,
        } );
      }
    })
  }

  updateCubeRotation(speedMultiplier) {
    this.cubes.forEach((cube) => {
      cube.rotation.x += this.rotationDelta * speedMultiplier;
      cube.rotation.z += this.rotationDelta * speedMultiplier;
    })
  }

  updateProps({planeDepth}) {
    this.cubeGroup.position.z = planeDepth
  }

  tick(speedMultiplier = 1) {
    this.updateCubeRotation(speedMultiplier)
  }

}
