import { Back, Linear, TweenMax } from "gsap/gsap-core"
import * as THREE from 'three'

export default class LogoCube extends THREE.Object3D {
  constructor(size = 11, rotationDelta = 0.004){
    super();

    this.type = 'LogoCube';
    this.size = size;
    this.rotationDelta = rotationDelta
    this.generate()
  }

  generate(){
    this.generateCube()
  }

  startScene(sceneName) {
    let duration = 5;
    switch (sceneName) {
      case 'intro':
        this.startIntro()
        break;
    }
  }

  startIntro() {
    const minDuration = 2.5
    const maxDuration = minDuration * 1.5
    TweenMax.to( this.cube.rotation, minDuration, {
      x: Math.PI / 4,
      delay: 0.2,
      ease: Back.easeOut
    } );

    this.cube.scale.x = this.cube.scale.y = this.cube.scale.z = 0

    TweenMax.to( this.cube.scale, maxDuration, {
      x: 1,
      y: 1,
      z: 1,
      delay: 0.2,
      ease: Back.easeOut
    } );

    TweenMax.to( this.cube.rotation, minDuration, {
      x: Math.PI / 4,
      delay: 0.2,
      ease: Back.easeOut
    } );

    return maxDuration
  }

  generateCube() {
    let cubeGeometry

    let loader = new THREE.TextureLoader();
    const materials = [
      new THREE.MeshBasicMaterial({map: loader.load('/right.png')}),
      new THREE.MeshBasicMaterial({map: loader.load('/left2.png')}),
      new THREE.MeshBasicMaterial({map: loader.load('/top2.png')}),
      new THREE.MeshBasicMaterial({map: loader.load('/top.png')}),
      new THREE.MeshBasicMaterial({map: loader.load('/right2.png')}),
      new THREE.MeshBasicMaterial({map: loader.load('/left.png')}),
    ];

    cubeGeometry = new THREE.BoxBufferGeometry(this.size, this.size, this.size);
    this.cube = new THREE.Mesh(cubeGeometry, materials);
    this.cube.rotation.y = Math.PI / 4;

    this.add(this.cube)
  }

  updateCubeRotation(speedMultiplier) {
    this.cube.rotation.x += this.rotationDelta * speedMultiplier;
  }

  updateProps({planeDepth}) {
    // this.cube.position.z = planeDepth
  }

  tick(speedMultiplier = 1) {
    this.updateCubeRotation(speedMultiplier)
  }

}
