import LogoCube from "canvas/LogoCube"
import World from "canvas/World"
import Backdrop from "./Backdrop"
import IsoPlanet from "./IsoPlanet"
import { TweenMax } from "gsap";

import { EMPTY, INITIALIZED, HOME, WEARE } from "canvas/SceneEnum"

export const debugData = {
  debug: true,
  rotateSpeed: 3,
  planeDepth: 60,
  bloomExposure:2,
  bloomThreshold:1,
  bloomStrength:0,
  bloomRadius:0,
  dotScale: 6,
  shiftAmount:0.002,
  speedDelta:1,
}

let world, logoCube, backdrop;
const stack = [];
let introAnimationComplete = false

export const timelineInitialState = {
  currentScene: EMPTY,
  pageScene: undefined,
  sceneTransitioning: false
}

let timelineParams = timelineInitialState
let setTimelineStateReact = (nextState) => console.warn("State handler not set", nextState)

export const generate = (canvas, clientParams, params, timelineStateSetter) => {
  setTimelineStateReact = timelineStateSetter

  world = new World(canvas, clientParams, params)

  logoCube = new LogoCube()
  world.add(logoCube)

  backdrop = new Backdrop()
  world.add(backdrop)

  const isoPlanetConfig = [
    {
    size: 0.7,
    radius: 10,
    tilt:  Math.PI / 10
    },
    {
      size: 0.5,
      radius: 18,
      tilt:  Math.PI / 8
    },
    {
      size: 1,
      radius: 16,
      tilt:  Math.PI / 8
    },
    {
      size: .2,
      radius: 10,
      tilt:  Math.PI / 8,
      rotationDelta: 0.02
    },
    {
      size: .2,
      radius: 10,
      tilt:  Math.PI / 2,
      rotationDelta: 0.02
    },

    {
      size: .2,
      radius: 10,
      tilt:  Math.PI / 4,
      rotationDelta: 0.02
    },
    {
      size: .1,
      radius: 10,
      tilt:  Math.PI / 5,
      rotationDelta: 0.02
    },
    {
      size: .7,
      radius: 18,
      tilt:  Math.PI / 8,
      rotationDelta: 0.004
    },
    {
      size: .3,
      radius: 18,
      tilt:  Math.PI / 4,
      rotationDelta: 0.015
    },
    {
      size: .3,
      radius: 18,
      tilt:  Math.PI / 2,
      rotationDelta: 0.01
    },
    {
      size: 2,
      radius: 30,
      tilt:  Math.PI / 8,
      rotationDelta: 0.006
    },
    {
      size: 4,
      radius: 50,
      tilt:  Math.PI / 8,
      rotationDelta: 0.004
    },
    {
      size: 4,
      radius: 60,
      tilt:  Math.PI / 7,
      rotationDelta: 0.002
    },
  ]

  isoPlanetConfig.forEach(({size, radius, tilt, rotationDelta}) => {
    let isoPlanet  = new IsoPlanet(size, radius, tilt, rotationDelta)
    isoPlanet.generate(world)//FIXME move add to here
    stack.push(isoPlanet)
  });
}

export const updateParams = (props) => {
  backdrop.updateProps(props)
  world.updateProps(props)
}

export const updateScene = (clientParams, debugData) => {
  const { pointer } = clientParams
  if(debugData.debug) {
    updateParams(debugData)
  }
  const speedDelta = debugData.speedDelta
  if(introAnimationComplete) {
    backdrop.tick(speedDelta)
    logoCube.tick(speedDelta)
  }
    logoCube.rotation.y = Math.PI / 5 * pointer.positionX - Math.PI / 10
    backdrop.rotation.x = Math.PI / 250 * pointer.positionY - Math.PI / 500
    backdrop.rotation.y = Math.PI / 125 * pointer.positionX - Math.PI / 250

  stack.forEach(displayObject => displayObject.updateProps(speedDelta))
  world.update(clientParams)
}

export const startScene = (nextScene, completeHandler) => {
  if(timelineParams.sceneTransitioning || timelineParams.currentScene === EMPTY) {
    console.log("Scene transitioning or not initialized", timelineParams.currentScene, nextScene)
    timelineParams.pageScene = () => startScene(nextScene, completeHandler)
    // setTimelineState({
    //   pageScene: () => startScene(nextScene, completeHandler)
    // })
  } else {
    animateSceneOut(timelineParams.currentScene, nextScene, completeHandler)
  }
}

const animateSceneOut = (scene, nextScene, completeHandler) => {
  console.log("Current scene out", scene)
  switch (scene) {
    case INITIALIZED:
    case HOME:
    case WEARE:
      //Nothing
      setTimelineState({
        sceneTransitioning: true,
      })
      animateSceneIn(nextScene, completeHandler)
      break;
  }
}

const animateSceneIn = (scene, completeHandler) => {
  console.log("Next scene in", scene)
  timelineParams.currentScene = scene
  let delay = -1
  switch (scene) {
    case INITIALIZED:
    case HOME:
      delay = setWorldNeutral()
      break;
    case WEARE:
      delay = setWorldSide()
      break;
  }

  if(delay > -1) animateSceneInCompleteSetup(delay, scene, completeHandler)
}

const animateSceneInComplete = (scene, completeHandler) => {
  console.log("animateSceneInComplete", scene, timelineParams.pageScene, completeHandler)
  timelineParams.currentScene = scene
  timelineParams.sceneTransitioning = false

  setTimelineState({
    currentScene: scene,
    sceneTransitioning: false,
  })

  if(completeHandler) completeHandler(scene)

  if(timelineParams.pageScene !== undefined) {
    console.log("Execute page")
    timelineParams.pageScene()
    timelineParams.pageScene = undefined
  }
}

const animateSceneInCompleteSetup = (delay, scene, callback) => {
  TweenMax.delayedCall(delay, () => animateSceneInComplete(scene, callback))
}

export const setWorldNeutral = () => {
  const duration = 2
  TweenMax.to( world.camera.position, 5, {
    z: 60,
    delay: 0.3,
    onComplete: () => introAnimationComplete = true
  } );

  TweenMax.to( world.scene.position, duration, {
    x: 0,
    z: 0,
  } );

  return duration
}

export const setWorldSide = () => {
  const duration = 2
  TweenMax.to( world.scene.position, duration, {
    x: -40,
    z: -100
  } );

  // TweenMax.to( world.camera.position, duration, {
  //   z: 150,
  //   delay: 0.3,
  //   onComplete: () => introAnimationComplete = true
  // } );

  return duration
}

export const startInitAnimation = () => {
  timelineParams.sceneTransitioning = true
  const duration = 5
  TweenMax.to( world.camera.position, duration, {
    z: 60,
    delay: 0.3,
    onComplete: initAnimationComplete
  } );

  logoCube.startScene('intro')
  backdrop.startScene('intro')

  animateSceneInCompleteSetup(duration, INITIALIZED)
}

export const initAnimationComplete = () => {
  introAnimationComplete = true
  timelineParams.currentScene = INITIALIZED
  timelineParams.sceneTransitioning = false
}

const setTimelineState = (nextState) => {
  timelineParams = timelineStateReducer(nextState, timelineParams)
  if(setTimelineStateReact) setTimelineStateReact(nextState)
}

export  const timelineStateReducer = (prevState, data) => {
  return {
    ...prevState, ...data
  }
}
